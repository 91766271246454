import React, { useState } from "react";
import logo from "../../../assets/Group 40944@2x.png";
import { theme_color } from "../../../utilities/colors";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import logo_white from "../../../assets/Group 40944@2x.png";
import Flex_box from "../../../utilities/flex_box";
import down_arrow from "../../../assets/down_arrow_white.png";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Button from "../../../utilities/Button";
import landing_bg from "../../../assets/Path1.png";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import zIndex from "@mui/material/styles/zIndex";
import Welcome_Comp from "./Welcome_Comp";
import Key_Features from "./Key_Features";
// import Contact_Form from "./Contact_Form";
import Contact_Form from "./New_Contact_Form";
import Why_SmartQ from "./Why_SmartQ";
import Subscriptions from "./Subscriptions";
import Footer from "../Nav_Comp/Footer";
import Nav_Comp from "../Nav_Comp";
import Other_Features from "./Other_Features";
import FadeInSection from "../../../utilities/FadeInSection";

const Id_Cont = ({ id, children }) => {
  return (
    <div style={{ width: "100%" }} id={id}>
      {children}
    </div>
  );
};

const Landing_Screen = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };


  return (
    <>
      {/* // <div
    //   style={{
    //     width: "100%",
    //     height: "100vh",
    //     overflowY: "auto",
    //     overflowX: "hidden",
    //   }}
    // >
    //   <div
    //     style={{
    //       width: "100%",
    //       position: "fixed",
    //       top: 0,
    //       right: 0,
    //       zIndex: 500,
    //     }}
    //   >
    //     <Nav_Comp open_modal={open_modal} />
    //   </div>
    //   <FadeInSection>
    //     <Id_Cont id={"nav_comp"}>
    //       <div
    //         style={{
    //           width: "100%",
    //           height: "90px",
    //         }}
    //       ></div>
    //     </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //     <Id_Cont id={"welcome_comp"}>
    //       <Welcome_Comp open_modal={open_modal} />
    //     </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //     <Id_Cont id={"key_features"}>
    //       <Key_Features />
    //     </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //     <Id_Cont id={"other_features"}>
    //       <Other_Features />
    //     </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //   <Id_Cont id={"why_smartq"}>
    //     <Why_SmartQ />
    //   </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //   <Id_Cont id={"subscriptions"}>
    //     <Subscriptions />
    //   </Id_Cont>
    //   </FadeInSection>
    //   <FadeInSection>
    //   <Id_Cont id={"contact_us"}>
    //     <Contact_Form open_modal={open_modal} />
    //   </Id_Cont>
    //   </FadeInSection>
    //   <Footer />
    
    // </div> */}

      <div
        style={{
          height: "100vh",
          width: "100%",
          overflowY: "auto"
        }}
      >


        <Nav_Comp open_modal={open_modal} />
        <section class="banner-sec">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <h2>Welcome to Yaarnbox…fostering Impactful Connections for brands and their consumers</h2>
                  <p>Beyond traditional surveys, we enable meaningful connections that unlock deeper insights.</p>
                  <p>Explore a dynamic ecosystem designed to redefine the way researchers and respondents connect and interact.</p>
                  <div class="">
                    <Link
                      to="/documentation" class="default-btn">
                      <span>Discover more</span>
                    </Link>
                    <a href="#"
                      onClick={open_modal}
                      class="default-btn">
                      <span>Start Now</span>
                    </a>
                  </div>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                  <img src="assets/images/banner.png" width="100%" />
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/shape1.png" class="shape shape-1" alt="banner" />
          <img src="assets/images/shape2.png" class="shape shape-2" alt="banner" />
        </section>
        <section class="feature-sec">
          <div class="container">
            <div class="row justify-content-center align-items-center mb-3">
              <div class="col-md-8">
                <h2 class="mb-3">Key Features</h2>
                <h6 class="mb-0">Designed for the ease of the researcher and respondent</h6>
                <p>Yaarnbox makes conducting and participating in research a breeze.</p>
              </div>
              <div class="col-md-4 text-end">
                <a href="#"
                  onClick={open_modal}
                  class="default-btn">
                  <span>Start Now</span>
                </a>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img src="assets/images/format.png" class="mb-3" width="80px" />
                    <h5>Diverse Study Formats</h5>
                    <p>Choose from engaging surveys with branching logic, rich multimedia content, and interactive group discussions with text chat, audio, and video conferencing.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img src="assets/images/secure.png" class="mb-3" width="80px" />
                    <h5>Secure and Reliable</h5>
                    <p>Yaarnbox employs industry-standard security measures to ensure the confidentiality and integrity of your data.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-3">
                <div class="features_box">
                  <span class="bg-shape"></span>
                  <div class="finner_box">
                    <img src="assets/images/analytics.png" class="mb-3" width="80px" />
                    <h5>Advanced Data Analytics</h5>
                    <p>Access powerful dashboards and reporting tools to uncover hidden patterns and gain an in-depth understanding of your data.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="feature-sec">
          <div class="container">
            <div class="row justify-content-center align-items-center mb-3">
              <div class="col-md-8 text-center">
                <h2 class="mb-3">Everything you need to get the people's opinion on anything</h2>
                <p>Create your surveys and studies withmultiple options</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Voice Calling</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Video Calling</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Chatting</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Polls</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Group Discussion</h5>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="card_box">
                  <div class="iconimg">
                    <img src="assets/images/format.png" width="45px" />
                  </div>
                  <h5>Surveys</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="research-sec ">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-lg-5 d-none d-lg-block">
                  <img src="assets/images/research.png" width="100%" />
                </div>
                <div class="col-lg-7">
                  <h4 class="mb-3">Deliver your studies to qualified respondents based on demographics and preferences, ensuring high-quality data collection and fast delivery.</h4>
                  <p>Boost your Efficiency by Streamlining your research process, saving valuable time and resources.</p>
                  <div class="dftcrd">
                    <strong>Effortlessly Design Studies:</strong> Craft engaging surveys and facilitate interactive group discussions with our user-friendly tools.
                  </div>
                  <div class="dftcrd">
                    <strong>Gain Deeper Insights:</strong> Leverage powerful data visualization and analytics to transform collected data into actionable insights.
                  </div>
                  <div class="">
                    <Link to="/signup" class="default-btn">
                      <span>Sign Up as a researcher</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
          <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
          <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
        </section>


        <section class="research-sec2">
          <div class="container">
            <div class="banner">
              <div class="row align-items-center">
                <div class="col-md-7">
                  <h4 class="mb-3">Deliver your studies to qualified respondents based on demographics and preferences, ensuring high-quality data collection and fast delivery.</h4>
                  <p>Boost your Efficiency by Streamlining your research process, saving valuable time and resources.</p>
                  <div class="dftcrd">
                    <strong>Effortlessly Design Studies:</strong> Craft engaging surveys and facilitate interactive group discussions with our user-friendly tools.
                  </div>
                  <div class="dftcrd">
                    <strong>Gain Deeper Insights:</strong> Leverage powerful data visualization and analytics to transform collected data into actionable insights.
                  </div>
                  <div class=" mt-5">
                    <Link to="/signup" class="default-btn">
                      <span>Sign Up to take part in surveys and start earning</span>
                    </Link>
                  </div>
                </div>
                <div class="col-md-5">
                  <img src="assets/images/research1.png" class="amimy" width="100%" />
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
          <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
          <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
        </section>
        <section class="subscriptions-sec">
          <div class="container">
            <div class="banner">
              <div class="row justify-content-center align-items-center mb-5">
                <div class="col-md-8 text-center">
                  <h2 class="mb-3">Subscriptions Plan</h2>
                  <p>Try Yaarnbox for Free: Explore the Basic Plan for free or opt for a 14-day trial of the Pro Plan.</p>
                </div>
              </div>
              <div class="row justify-content-center  mb-3">
                <div class="col-lg-4 col-md-6 mb-3">
                  <div class="subbox">
                    <div class="sub-innerbox">
                      <h2>Basic Plan</h2>
                      <h6>Free</h6>
                      <p class="subtxt">Limited survey features Standard data visualization tools</p>
                    </div>
                    <img src="assets/images/shape5.png" class="shape shape-6" alt="banner" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                  <div class="subbox">
                    <div class="sub-innerbox">
                      <h2>Pro Plan</h2>
                      <h6>$29.99/month</h6>
                      <p class="subtxt">Enhanced survey capabilities Advanced data analytics Real time communication tools</p>
                    </div>
                    <img src="assets/images/shape5.png" class="shape shape-6" alt="banner" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                  <div class="subbox">
                    <div class="sub-innerbox">
                      <h2>Enterprise Plan</h2>
                      <h6>Custom Pricing</h6>
                      <p class="subtxt">Tailored solutions for large-scale research projects dedicated support and additional features</p>
                    </div>
                    <img src="assets/images/shape5.png" class="shape shape-6" alt="banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
          <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
          <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
        </section>

        <section class="contact-sec">
          <div class="container">
            <div class="banner">
              <div class="row justify-content-center align-items-center mb-5">
                <div class="col-lg-8 order-2 mb-3">
                  <form class="contactForm ">
                    <div class="section-title">
                      <h2>Get In Touch</h2>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <input type="text" name="txt_name" id="txt_name" class="form-control" placeholder="Name*" required="" pattern="[a-zA-Z]{''}*" maxlength="20" minlength="3" />
                        </div>
                      </div>

                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <input type="email" name="txt_email" id="txt_email" class="form-control" placeholder="Email" required="" />
                        </div>
                      </div>

                      <div class="col-lg-6 mb-3">
                        <div class="form-group">
                          <input type="text" name="txt_contact_number" id="txt_contact_number" placeholder="Phone" required="" maxlength="10" minlength="10" pattern="[0-9]{10}" class="form-control" />
                        </div>
                      </div>

                      <div class="col-lg-6 mb-3">
                        <div class="form-group has-error">
                          <input type="text" name="txt_subject" id="txt_subject" class="form-control" placeholder="Subject" required="" pattern="[a-zA-Z]*" />
                        </div>
                      </div>

                      <div class="col-lg-12 mb-3">
                        <div class="form-group">
                          <textarea name="txt_message" class="form-control" id="txt_message" cols="30" rows="8" placeholder="Write message" required="" pattern="[a-zA-Z]*"></textarea>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <a type="submit" class="default-btn" >
                          Send Message
                        </a>
                      </div>
                    </div>

                  </form>
                </div>

                <div class="col-lg-4 order-1 mb-3">
                  <img src="assets/images/contact1.png" width="280px" class="mb-3 d-none d-lg-block" />
                  <h4 class="mb-3 text-primary">Get In Touch with Our Experts</h4>
                  <p class="mb-3">Want find out how Yaarnbox can help your organization? We'd love to hear about from you.</p>
                  <h6 class="mb-3 text-primary">Integrate towards innovation</h6>
                  <p>Become a Yaarnbox partner to Launch, Run and Grow your business globally.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>

    </>
  );
};

export default Landing_Screen;
