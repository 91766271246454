import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Progress_bar from "../../../utilities/Progress_bar";
import "./study_box.css";
import { useNavigate } from "react-router-dom";
import Button from "../../../utilities/Button";
import { get_industry_name } from "../../../utilities/select_arr";
import { get_percentage } from "../../../utilities/Get_percentage";
import { theme_bg } from "../../../utilities/colors";

const Study_Box = ({ progress, item }) => {
  const { study_lookup, surveyres_lookup } = item;
  const study = study_lookup[0];

  const max_resp = parseInt(study?.totalrespondents);
  const totalResponse = surveyres_lookup?.length;
  const totalResponse_perc = get_percentage(totalResponse, max_resp) + "%";
  const completedResponse =
    surveyres_lookup?.length > 0 &&
    surveyres_lookup?.filter((el) => {
      return el?.iscompleted == true;
    })?.length;
  const completedResponse_perc =
    get_percentage(completedResponse, max_resp) + "%";

  const navigate = useNavigate();

  return (
    <div
      className="study_box_cont"
      style={{
        gap: 12,
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        {study?.studyname}
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(study?.studyoninductry)}
      </span>

      <>
        <span
          style={{
            fontSize: "15px",
          }}
        >
          <span style={{ fontWeight: 700 }}>
            {totalResponse}/{max_resp}{" "}
          </span>{" "}
          <span style={{ fontWeight: 500 }}>Respondents</span>
        </span>

        <Flex_box
          style={{
            justifyContent: "start",
            alignItems: "",
            gap: 10,
            marginTop: "5px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              marginTop: "0px",
              width: "65px",
            }}
          >
            {totalResponse_perc}
          </p>
          <Flex_box
            style={{
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: 8,
            }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              Response rate
            </span>
            <Progress_bar progress={totalResponse_perc} />
          </Flex_box>
        </Flex_box>
        <Flex_box
          style={{
            justifyContent: "start",
            alignItems: "",
            gap: 10,
            marginTop: "5px",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              marginTop: "0px",
              width: "65px",
            }}
          >
            {completedResponse_perc}
          </p>
          <Flex_box
            style={{
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: 8,
            }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              Completion rate
            </span>
            <Progress_bar progress={completedResponse_perc} />
          </Flex_box>
        </Flex_box>

        <p
          style={{
            fontSize: "12px",
            marginTop: "5px",
            lineHeight: 1.5,
            height: "80px",
            overflowY:"auto"
          }}
        >
          {study?.description}
        </p>
      </>

      <Flex_box
        style={{
          width: "",
          gap: 25,
          justifyContent: "start",
        }}
      >
        <Button
          id="btn_comp"
          style={{ height: "50px", fontSize: "16px", fontWeight: "500" }}
          onClick={() => {
            navigate("/study-details", {
              state: { study_id: study?._id, type: "survey" },
            });
          }}
          label={"View Details"}
        ></Button>
        <Button
          id="btn_comp"
          style={{
            height: "50px",
            fontSize: "16px",
            fontWeight: "500",
            backgroundColor: "#FFCC99",
            color: "#000",
            border:"2px solid #FFCC99"
          }}
          onClick={() => {
            navigate("/survey-analysis", {
              state: { study_id: study?._id,studyname:study?.studyname },
            });
          }}
          label={"Analyse Results"}
        ></Button>
      </Flex_box>
    </div>
  );
};

export default Study_Box;
