import React, { useEffect, useState } from "react";
import Page_heading from "../../../../utilities/Page_heading";
import Flex_box from "../../../../utilities/flex_box";
import survey_pic from "../../../../assets/Image.png";
import { theme_bg, theme_color } from "../../../../utilities/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { GetSurveyByStudyId_api, image_url } from "../../../../apis/apis";
import Respondent_Table from "./Respondent_Table";

const Study_Block = ({ study_id }) => {
  // const { state } = useLocation();

  // const is_gd = state.type == "gd";
  const navigate = useNavigate();
  const [survey_data, setsurvey_data] = useState();

  const get_survey_details = () => {
    GetSurveyByStudyId_api({ studyid: study_id })
      .then((res) => {
        setsurvey_data(res.data.Res);
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const go_to_preview = () => {
    const q_data = {
      SurveyData: {
        surveyid: survey_data?.surveydata?._id,
        image: "",
        surveytitle: survey_data?.surveydata?.surveytitle,
        surveydescription: survey_data?.surveydata?.surveydescription,
      },
      Questions: survey_data?.questionsdata?.map((el) => {
        if (el?.QuestionData?.questiontype == 1) {
          return {
            questiontitle: el?.QuestionData?.questiontitle,
            questiontype: 1,
          };
        } else {
          return {
            questiontitle: el?.QuestionData?.questiontitle,
            questiontype: el?.QuestionData?.questiontype,
            Option: el?.Options?.map((li) => {
              return {
                optiontitle: li?.optiontitle,
                answer: li?.answer,
              };
            }),
          };
        }
      }),
      image: "",
    };

    localStorage.setItem("question_data", JSON.stringify(q_data));
    // console.log(q_data);
    navigate("/preview-survey");
  };

  useEffect(get_survey_details, []);

  return (
    <>
      <div
        style={{
          padding: "20px 0px",
        }}
      >
        <Page_heading label={"Survey"} />
        <Flex_box
          className="responsive_width_35"
          style={{
            width: "",
            flexDirection: "column",
            alignItems: "start",
            padding: "12px",
            boxSizing: "border-box",
            boxShadow: "0px 0px 30px #A6A6A633",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "230px",
              objectFit: "cover",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            src={
              survey_data?.surveydata?.image
                ? image_url + survey_data?.surveydata?.image
                : survey_pic
            }
          />
          <b style={{ fontSize: "18px", marginTop: "10px" }}>
            {survey_data?.surveydata?.surveytitle}
          </b>
          <span
            style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px" }}
          >
            Questions Added : {survey_data?.questionsdata?.length}
          </span>
          <span style={{ fontSize: "12px", marginTop: "9px" }}>
            {survey_data?.surveydata?.surveydescription}
          </span>
          <Flex_box
            style={{
              // width: "",
              gap: 25,
              // justifyContent: "start",
              padding: "20px 0px 10px 0px",
            }}
          >
            <button
              id="btn_comp"
              style={{
                fontWeight: "600",
                color: theme_color,
                backgroundColor: theme_bg,
                border: "2px solid " + theme_color,
              }}
              onClick={go_to_preview}
            >
              Edit
            </button>
            <button
              id="btn_comp"
              style={{
                fontWeight: "600",
                backgroundColor: "red",
                color: "#fff",
                border: "2px solid red",
              }}
            >
              Delete
            </button>
          </Flex_box>
        </Flex_box>
      </div>
      <Respondent_Table survey_data={survey_data} />
    </>
  );
};

export default Study_Block;
