import Flex_box from "../../../utilities/flex_box";
import respondent_icon from "../../../assets/Group 48287.svg";
import bg_p from "../../../assets/Path37581.png";
import Button from "../../../utilities/Button";
import dot_icon from "../../../assets/dot.png";
import { useMediaQuery } from "@mui/material";
import FadeInSection from "../../../utilities/FadeInSection";

const Points_Comp = ({
  label,
  sub_text_arr,
  points_arr,
  image,
  bg,
  reverse,
  button_elem,
  comp_style,
  points_icon,
  no_point_gap,
  new_ui
}) => {
  const comp_style_obj = comp_style || {};
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <FadeInSection>
      <Flex_box
        style={{
          flexWrap: "wrap",
          position: "relative",
          padding: !max_w_1000 ? "40px 0px" : "0px 0px",
          backgroundImage:
            bg == "true" ? `url(${bg_p})` : bg ? `url(${bg})` : "",
          ...comp_style_obj,
        }}
      >
        <Flex_box
          style={{
            gap: 60,
            flexWrap: "wrap",
            zIndex: 102,
            flexDirection: reverse ? "row-reverse" : "row",
            ...comp_style_obj,
            // justifyContent:"start"
          }}
        >
          {image && !max_w_1000 && (
            <Flex_box
              style={{
                width: "40%",
                height: "82vh",
                minWidth: "200px",
                gap: 20,
              }}
            >
              <img
                src={image}
                style={{
                  height: "55vh",
                }}
                className="hover_img"
              />
            </Flex_box>
          )}

          <div
            style={{
              width: max_w_1000 ? "90%" : image ? "40%" : "70%",
              minWidth: "200px",
              textWrap: "wrap",
              whiteSpace: "wrap",
              // gap: 20,
              // flexDirection: "column",
              // justifyContent: "start",
              // alignItems: "start"
            }}
          >
            {label && (
              <>
                <b
                  className="intro_heading_text"
                  style={{ fontStyle: "italic" }}
                >
                  {label}
                </b>
                <br className="max_100_hidden" />
                <br />
              </>
            )}
            {sub_text_arr?.length > 0 &&
              sub_text_arr?.map((el, index) => {
                return (
                  <>
                    <span
                      key={index}
                      className="intro_sub_text"
                      style={{
                        fontStyle: "italic",
                        fontWeight: el?.bold ? 600 : 300,
                      }}
                    >
                      {el?.text}
                    </span>
                    <br />
                    <br />
                  </>
                );
              })}
            {points_arr?.length > 0 &&
              points_arr?.map((el, index) => {
                return (
                  <>{
                    new_ui &&
                    <div class="dftcrd">
                      {el?.text}
                    </div>
                  }
                    {
                      !new_ui &&
                      <>
                        {el?.elem && <br />}

                        <Flex_box
                          style={{
                            width: "",
                            justifyContent: "start",
                            alignItems: "",
                          }}
                        >
                          {points_icon && (
                            <img
                              src={dot_icon}
                              style={{ height: "25px", marginTop: "-3px" }}
                              className="hover_img"
                            />
                          )}
                          <span
                            key={index}
                            style={{ fontSize: max_w_1000 ? "12px" : "14px" }}
                          >
                            {el?.heading && <b>{el?.heading + ": "}</b>}
                            {el?.elem}
                            <span>{el?.text}</span>
                          </span>
                        </Flex_box>

                        {!no_point_gap && <br />}

                        {el?.elem && <br />}
                      </>
                    }


                  </>
                );
              })}
            {button_elem && (
              <Flex_box
                style={{
                  width: "",
                  boxSizing: "border-box",
                  justifyContent: "start",
                  gap: 20,
                  marginTop: "20px",
                }}
              >
                {button_elem}
              </Flex_box>
            )}
          </div>
        </Flex_box>
      </Flex_box>
    </FadeInSection>
  );
};

export default Points_Comp;
