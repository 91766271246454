import React from "react";
import { Modal } from "./Modal";
import Flex_box from "./flex_box";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  close_alert_action,
} from "../redux_prog/actions/base_action";
import error_icon from "../assets/Image 107.png";
import success_icon from "../assets/Image 110.png";

const Alert = () => {
  const alert_obj = useSelector((state) => state?.baseReducer?.alert_obj);
  //   console.log(alert_obj);
  const {
    type,
    msg,
    title,
    btn_text,
    isopen,
    is_confirm,
    onSuccess,
    success_btn_text,
  } = alert_obj;
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isopen}>
      <Flex_box
        className="responsive_width_35"
        style={{
          flexDirection: "column",
          gap: 15,
          width: "",
          padding: "5px 0px",
        }}
      >
        <img
          src={type == "error" ? error_icon : success_icon}
          style={{
            height: "80px",
          }}
        />
        <b
          style={{
            fontSize: "24px",
          }}
        >
          {title}
        </b>
        <span
          style={{ textAlign: "center", fontSize: "15px", lineHeight: 1.4 }}
        >
          {msg}
        </span>
        <Flex_box
          style={{
            gap: 20,
          }}
        >
          <button
            id="btn_comp"
            style={{
              border: is_confirm ? "2px solid red" : "",
              ...(is_confirm
                ? {
                    marginTop: "10px",
                    backgroundColor: "red",
                  }
                : {
                    marginTop: "10px",
                  }),
            }}
            onClick={() => {
              dispatch(close_alert_action());
            }}
          >
            {btn_text || "Cancel"}
          </button>
          {is_confirm && (
            <button
              id="btn_comp"
              style={{
                marginTop: "10px",
                backgroundColor: "green",
                border: "2px solid green",
              }}
              onClick={() => {
                if (onSuccess) {
                  onSuccess();
                }
                dispatch(close_alert_action());
              }}
            >
              {success_btn_text || "Ok"}
            </button>
          )}
        </Flex_box>
      </Flex_box>
    </Modal>
  );
};

export default Alert;
