import Pre_login from "./screens/Auth_Screens/Pre_login";
import "./App.css";
import Sign_up from "./screens/Auth_Screens/SignUp";
import Sign_in from "./screens/Auth_Screens/SignIn";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Researcher_authentication from "./screens/Auth_Screens/Researcher_authentication";
import Respondent_authentication from "./screens/Auth_Screens/Respondent_authentication";
import OTP_Verify_Comp from "./screens/Auth_Screens/OTP_Verification";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./screens/Researcher_screens/Dashboard";
import Create_study from "./screens/Researcher_screens/My_Studies/Create_study";
import Select_Study_Type from "./screens/Researcher_screens/My_Studies/Select_Study_Type";
import Create_Survey from "./screens/Researcher_screens/My_Studies/Create_Survey";
import Add_Questions from "./screens/Researcher_screens/My_Studies/Create_Survey/Add_Questions";
import Preview_Survey from "./screens/Researcher_screens/My_Studies/Create_Survey/Preview_Survey";
import Create_GD from "./screens/Researcher_screens/My_Studies/Create_GD";
import Send_Invite from "./screens/Researcher_screens/My_Studies/Send_Invite";
import GD_Chat from "./screens/Researcher_screens/My_Studies/GD_Chat";
import My_Profile from "./screens/Researcher_screens/Profile/My_Profile";
import FAQ from "./screens/Researcher_screens/FAQ";
import Study_Details from "./screens/Researcher_screens/My_Studies/Study_Details";
import Terms_conditions from "./screens/Terms_conditions";
import Privacy_Policy from "./screens/Privacy_Policy";
import Survey_Analysis from "./screens/Researcher_screens/My_Studies/Survey_Analysis";
import Edit_Profile from "./screens/Researcher_screens/Profile/Edit_Profile";
import Study_Intro_Page from "./screens/Researcher_screens/My_Studies/Intro_Page";
import Select_Sub_Page from "./screens/Researcher_screens/Subscriptions/Select_Sub_Page";
import Price_Page from "./screens/Researcher_screens/Subscriptions/Price_Page";
import { respondent_routes } from "./config/respondent_routes";
import { Modal } from "./utilities/Modal";
import Flex_box from "./utilities/flex_box";
import error_icon from "./assets/Image 107.png";
import Alert from "./utilities/Alert";
import { useState } from "react";
import { set_intro_status_action } from "./redux_prog/actions/auth_action";
import { researcher_routes } from "./config/researcher_routes";
import Loading from "./utilities/Loading";
import Landing_Screen from "./screens/Intro_Screens/Landing_Screen";
import About_Screen from "./screens/Intro_Screens/About_Screen";
import Features from "./screens/Intro_Screens/Features";
import Pricing from "./screens/Intro_Screens/Pricing";
import Support from "./screens/Intro_Screens/Support";
import Documentation from "./screens/Intro_Screens/Documentation";
import Forgot_Pass from "./screens/Auth_Screens/Forgot_Pass";
import Take_Survey from "./screens/Respondant_screens/Take_Survey";
import Add_Temp_User from "./screens/Auth_Screens/Add_Temp_User";
import Pricing_Page from "./screens/Researcher_screens/Subscriptions_Packages/Pricing_Page"

const auth_routes = [
  {
    path: "/",
    element: <Landing_Screen />,
    // element: <Pre_login />,
    // loader: rootLoader,
    // children: [
    //   {
    //     path: "login",
    //     element: <Sign_in />,
    //   },
    // ],
  },
  {
    path: "about-us",
    element: <About_Screen />,
  },
  {
    path: "features",
    element: <Features />,
  },
  {
    path: "pricing",
    element: <Pricing />,
  },
  {
    path: "support",
    element: <Support />,
  },
  {
    path: "documentation",
    element: <Documentation />,
  },
  {
    path: "login",
    element: <Sign_in />,
  },
  {
    path: "signup",
    element: <Sign_up />,
  },
  {
    path: "researcher_auth",
    element: <Researcher_authentication />,
  },
  {
    path: "respondent_auth",
    element: <Respondent_authentication />,
  },
  {
    path: "otp-verify",
    element: <OTP_Verify_Comp />,
  },
  {
    path: "terms-conditions",
    element: <Terms_conditions />,
  },
  {
    path: "privacy-policy",
    element: <Privacy_Policy />,
  },
  {
    path: "/select-sub",
    element: <Select_Sub_Page />,
  },
  {
    path: "/sub-price",
    element: <Price_Page />,
  },
  {
    path: "/reset-pass",
    element: <Forgot_Pass />,
  },
  {
    path: "/take-survey",
    element: <Take_Survey />,
  },
  {
    path: "/add-temp-user",
    element: <Add_Temp_User />,
  },
  {
    path: "/sub-pricing",
    element: <Pricing_Page />,
  },
];

function Navigation_comp() {
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const intro_completed = useSelector(
    (state) => state?.authReducer?.intro_completed
  );
  const is_authenticated = useSelector(
    (state) => state?.authReducer?.is_authenticated
  );
  const loading = useSelector((state) => state?.baseReducer?.loading);
  const dispatch = useDispatch();

  // const protected_routes = is_authenticated ? (login_type == 2 ? researcher_routes : respondent_routes) : [];
  const protected_routes =
    login_type == 2
      ? researcher_routes
      : login_type == 3
      ? respondent_routes
      : [];

  const router = createHashRouter([...auth_routes, ...protected_routes]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100hw",
        zIndex:1
        // paddingBottom: "100px",
      }}
    >
      {/* {
                !intro_completed &&
                <Splash_Screen />
            } */}
      {
        // intro_completed &&
        <RouterProvider router={router} />
      }
      <Alert />
      {loading && <Loading />}
    </div>
  );
}

export default Navigation_comp;
