import React, { useState } from "react";
import Button from "../../../utilities/Button";
import { Modal } from "../../../utilities/Modal";
import Flex_box from "../../../utilities/flex_box";
import Select_box from "../../../utilities/Select_box";
import { useDispatch, useSelector } from "react-redux";
import Text_Input from "../../../utilities/Text_Input";
import { convert_into_base64 } from "../../../utilities/Image_conv";
import { upload_kyc_docs_api, send_kyc_review_request_api } from "../../../apis/apis";
import { doc_type_options } from "../../../utilities/select_arr";

const Kyc_Form = ({ modal_props }) => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);

  const { modalVisible, setmodalVisible, get_KYCs } = modal_props;

  const [doc_type, setdoc_type] = useState("");
  const [document, setdocument] = useState("");

  const create_KYC = () => {
    if (document) {
      upload_kyc_docs_api({
        user_id: user_data?._id,
        kyc_docs: [
          {
            doc_type,
            document,
          },
        ],
      })
        .then((res) => {
          send_kyc_review_request_api({
            noti_data: {
              UserId: user_data?._id,
              toId: user_data?._id,
              msg:
              "You have a new request for KYC document review",
              title: "KYC Review Request",
              type: "KYC",
              notificatonTypeId: user_data?._id,
              sender_type: 2,
            },
            }).then((res) => {
              console.warn(res);
              get_KYCs();
              setmodalVisible(false);
            })
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      alert("Please select document first!");
    }
  };

  const handleSubmit = () => {
    create_KYC();
  };

  return (
    <>
      <Button
        label="Add"
        onClick={() => {
          setmodalVisible(true);
        }}
        style={{
          width: "100px",
        }}
      />
      <Modal
        content_style={{
          width: "50vw",
          padding: "0px",
          minWidth: "300px",
        }}
        label={"Upload KYC Document"}
        onClose={() => {
          setmodalVisible(false);
        }}
        isOpen={modalVisible}
        footer={
          <Flex_box
            style={{
              padding: "10px",
              justifyContent: "end",
              width: "97%",
            }}
          >
            <Button loading={loading} onClick={handleSubmit} label={"Submit"} />
          </Flex_box>
        }
      >
        <div
          style={{
            width: "97.5%",
            padding: "10px",
            paddingTop: "0px",
            overflowY: "auto",
          }}
        >
          <Select_box
            cont_style={{
              width: "100%",
            }}
            select_cont_style={{
              width: "98%",
            }}
            placeholder={"Select document type"}
            options={doc_type_options}
            value={doc_type}
            onChange={(e) => {
              setdoc_type(e.target.value);
            }}
          />
          <Text_Input
            label={"Upload File"}
            type="file"
            cont_style={{
              marginTop: "10px",
              width: "98%",
            }}
            disabled={!doc_type}
            onChange={(e) => {
              convert_into_base64(e.target.files[0], (base64) => {
                setdocument(base64);
              });
            }}
          />
          {document && (
            <img
              src={document}
              style={{
                marginTop: "10px",
                width: "150px",
                height: "150px",
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default Kyc_Form;
