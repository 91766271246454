import React, { useEffect, useState } from "react";
import {
  del_kyc_docs_api,
  get_kyc_docs_api,
  image_url,
} from "../../../apis/apis";
import Layout from "../../../utilities/Layout";
import del_icon from "../../../assets/delete.png";
import Table from "../../../utilities/Table";
import Flex_box from "../../../utilities/flex_box";
import Page_heading from "../../../utilities/Page_heading";
import Kyc_Form from "./Kyc_Form";
import { useDispatch, useSelector } from "react-redux";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import { get_doc_type } from "../../../utilities/select_arr";
import DataTable from "../../../utilities/DataTable";

const KYC = () => {
  const dispatch = useDispatch();
  const [KYC_arr, setKYC_arr] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [modalVisible, setmodalVisible] = useState(false);
  const kyc_obj_data = {
    Name: "",
    Amount: 0,
    Description: "",
    Cycle: "Week",
    Discount: 0,
    study_limit: 0,
    coin_limit: 0,
    video_call_hrs: 0,
    max_gd_participants: 0,
  };
  const { user_data } = useSelector((state) => state?.authReducer);

  const [KYC_data, setKYC_data] = useState(kyc_obj_data);

  const [dur_num, setdur_num] = useState(0);
  const [dur_type, setdur_type] = useState("Week");
  const [currentPage, setcurrentPage] = useState(1);
  const skip = (currentPage - 1) * 10;

  const get_KYCs = () => {
    dispatch(loading_action(true));
    get_kyc_docs_api({ user_id: user_data?._id })
      .then((res) => {
        if (res.data.status == 1) {
          setKYC_arr(res.data.Res);
        }
        dispatch(loading_action(false));
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.error(err);
      });
  };

  const modal_props = {
    modalVisible,
    setmodalVisible,
    KYC_data,
    setKYC_data,
    dur_num,
    setdur_num,
    dur_type,
    setdur_type,
    get_KYCs,
  };

  useEffect(get_KYCs, []);

  const t_heads = [
    {
      label: "S No.",
      accessor: "index",
      element: (el, index) => {
        return (
          <div
            style={{
              width: "80px",
            }}
          >
            <span>{skip + index + 1}</span>
          </div>
        );
      },
    },
    {
      label: "Name",
      accessor: "doc_type",
      tr_style: {
        minWidth: "120px",
      },
      element: (el) => {
        return <span>{get_doc_type(el?.doc_type)}</span>;
      },
    },
    {
      label: "Document",
      accessor: "document",
      tr_style: {
        minWidth: "150px",
      },
      element: (el) => {
        return (
          <>
            <img
              src={image_url + "kyc_docs/" + el?.document}
              style={{
                height: "100px",
                // width: "100px",
              }}
            />
          </>
        );
      },
    },
    {
      label: "Verified",
      accessor: "document",
      tr_style: {
        minWidth: "150px",
      },
      element: (el) => {
        return (
          <Flex_box
            style={{
              width: "120px",
              padding: "5px",
              borderRadius: "8px",
              color: "#fff",
              fontWeight: "500",
              backgroundColor: el?.verified ? "green" : "red",
            }}
          >
            <span>{el?.verified ? "Verified" : "Not verified"}</span>
          </Flex_box>
        );
      },
    },
    {
      label: "Remark",
      accessor: "remark",
      tr_style: {
        minWidth: "150px",
      },
    },
    {
      label: "Action",
      accessor: "document",
      tr_style: {
        minWidth: "150px",
      },
      element: (el) => {
        return (
          <Flex_box
            style={{
              width: "120px",
              justifyContent: "start",
              gap: 10,
            }}
          >
            <img
              src={del_icon}
              style={{
                height: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  alert_action({
                    type: "error",
                    title: "Delete?",
                    msg: "Do you want to delete this document?",
                    is_confirm: true,
                    onSuccess: () => {
                      del_kyc_docs_api({
                        _id: el?._id,
                      })
                        .then((res) => {
                          console.warn(res.data);
                          get_KYCs();
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    },
                  })
                );
              }}
            />
          </Flex_box>
        );
      },
    },
  ];

  const onPrev = (val) => {
    setcurrentPage(val);
  };

  const onNext = (val) => {
    setcurrentPage(val);
  };

  useEffect(() => {
    if (modalVisible == false) {
      setKYC_data(kyc_obj_data);
    }
  }, [modalVisible]);

  return (
    <Layout>
      <Flex_box
        style={{
          justifyContent: "space-between",
          paddingTop: "10px",
          paddingLeft: "15px",
          width: "98%",
        }}
      >
        <Page_heading label={"KYC document"} />
        <Kyc_Form modal_props={modal_props} />
      </Flex_box>
      {KYC_arr?.length > 0 && (
        <>
          <div
            style={{
              height: "60vh",
              overflow: "auto",
              marginTop: "10px",
            }}
          >
            <DataTable fontSize={"14px"} heads={t_heads} rows={KYC_arr} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default KYC;
