import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import Welcome from "./Welcome";
import User_Experiance from "./User_Experiance";
import Powerful_research from "./Powerful_research";
import Data_analysis from "./Data_analysis";
import Table_Comp from "./Table_Comp";
import Diff_exp from "./Diff_exp";
import { Link } from "react-router-dom";

const Features = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {/* <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>
      <div
        style={{
          width: "100%",
          height: "90px",
        }}
      ></div>
      <div>
        <Welcome open_modal={open_modal} />
        <User_Experiance />
        <Powerful_research />
        <Data_analysis />
        <Table_Comp />
        <Diff_exp />
      </div> */}
      <Nav_Comp />
      <section class="innerbanner-sec">
        <div class="container">
          <div class="page-title">
            <h1 class="page-title-inner">Features</h1>
            <nav aria-label="breadcrumb" class="breadcrumb-row "><ul class="breadcrumb"><li class="breadcrumb-item"><a href="/">Home</a></li><li class="breadcrumb-item active" aria-current="page">Features</li></ul></nav>
          </div>
        </div>
        <img src="assets/images/shape1.png" class="shape shape-1" alt="banner" />
        <img src="assets/images/shape2.png" class="shape shape-2" alt="banner" />
      </section>

      <section class="research-sec2">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <div class="col-md-7">
                <h4 class="mb-3">Unleash the Power of Seamless Research</h4>
                <p>Yaarnbox empowers researchers and respondents with a comprehensive suite of features designed to streamline the market research process and unlock valuable insights. Here's a closer look at the functionalities that make Yaarnbox your one-stop solution for effective research</p>

                <div class=" mt-5">
                  <a href="#" class="default-btn">
                    <span>Contact Us</span>
                  </a>
                </div>
              </div>
              <div class="col-md-5">
                <img src="assets/images/about.png" class="amimy" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
        <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
        <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
      </section>
      <section class="position-relative py-3">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <div class="col-md-4 ">
                <img src="assets/images/benefits.svg" class="amimy" width="100%" />
              </div>
              <div class="col-md-8">
                <h3 class="mb-3" title="Mission">
                  {/* <!-- <img src="assets/images/goal.png" alt="Mission" width="50px">  --> */}
                  User-Friendly Experience:</h3>
                <p>Navigate the platform with ease, regardless of your technical expertise. Our user-friendly interface allows you to manage your research projects and participate in studies effortlessly.</p>
                <p class="mb-0"><strong>Seamless Account Management:</strong> Sign up for free as a researcher or respondent and manage your profile information, preferences, and communication settings within a centralized hub.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="position-relative py-5">
        <div class="container">
          <div class="banner">
            <div class="row justify-content-center align-items-center mb-3">
              <div class="col-md-12">
                <h2 class="mb-3">The Value</h2>
                <p>Yaarnbox offers a comprehensive suite of features to cater to all your research needs</p>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="dftcrd">
                  <strong>Survey Creation: </strong>Craft engaging surveys with various question formats, branching logic, quota management, and multimedia content integration.
                </div>
                <div class="dftcrd">
                  <strong>Group Discussions: </strong>Facilitate interactive group discussions with real-time text chat, audio conferencing, and video capabilities.
                </div>
                <div class="dftcrd">
                  <strong>Respondent Matching:  </strong>Target the right audience for your studies using advanced demographic filters and preferences.
                </div>
                <div class="dftcrd">
                  <strong>Data Analytics and Reporting: </strong>Gain in-depth understanding of your data through intuitive dashboards, insightful reports, and powerful visualization tools.
                </div>
                <div class="dftcrd">
                  <strong>Data Security and Compliance:</strong>We prioritize the security of your data with industry-standard encryption protocols and rigorous KYC verification processes.
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
        <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
        <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
      </section>

      <section class="position-relative py-5">
        <div class="container">
          <div class="banner">
            <div class="row align-items-center">
              <div class="col-md-7">
                <h2>Enhanced Data Analysis and Reporting:</h2>
                <h5 class="my-4">Gain instant insights into your data with interactive dashboards that visualize key metrics and trends from your studies.</h5>
                <p><strong>Advanced Reporting: </strong> Generate comprehensive reports with detailed data breakdowns, customizable charts, and graphs to present your findings effectively.</p>
                <p>Demonstrate results to your stakeholders effectively!</p>

              </div>
              <div class="col-md-5">
                <img src="assets/images/benefits.svg" class="amimy" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
        <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
        <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
      </section>


      <section class="position-relative py-5">
        <div class="container">
          <div class="row justify-content-center align-items-center mb-5">
            <div class="col-md-8 text-center">
              <h2 class="mb-3">Ready to Experience the Yaarnbox Difference?</h2>
              <p>Join the thousands of researchers and respondents already using Yaarnbox to unlock the power of market research <strong>Call to Action section</strong></p>
            </div>
          </div>
          <div class="row align-items-center justify-content-center ">
            <div class="col-md-5">
              <div class="call_action_box ">
                <h5>Researchers:</h5>
                <p>Sign up for a free trial and experience the comprehensive research toolkit Yaarnbox offers.</p>
                <Link to="/signup" class="default-btn clip-btn">
                  <span>Sign Up for Free Trial</span>
                </Link>
              </div>
            </div>
            <div class="col-md-5">
              <div class="call_action_box ">
                <h5>Respondents:</h5>
                <p>Create your free account and start participating in exciting studies and earning rewards!</p>
                <Link to="/signup" class="default-btn clip-btn">
                  <span>Sign Up for Free</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/images/shape1.png" class="shape shape-3" alt="banner" />
        <img src="assets/images/shape-4.png" class="shape shape-4" alt="banner" />
        <img src="assets/images/shape3.svg" class="shape shape-5" alt="banner" />
      </section>

      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Features;
