import React, { useEffect, useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Price_Box from "../Subscriptions_Packages/Pricing_Box";
import Subscription_Box from "./Subscriptions_Box";
import { get_subscriptions_api } from "../../../apis/apis";

const Sub_Pages = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setSubscriptions(res.data.Res); // Assuming res.data.Res holds the subscription data
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const firstSub = subscriptions?.[0] || "";

  return (
    <Layout no_sidebar={false}>
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "70vh",
          lineHeight: 1.8,
          paddingTop: "20px",
          overflowY: "auto",
        }}
      >
        <b style={{ fontSize: "24px" }}>Subscriptions</b>
        <span style={{ fontSize: "14px" }}>
          Unlock unlimited access to all the features
        </span>

        <Flex_box
          style={{
            gap: "30px",
            marginTop: "25px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {subscriptions?.map((el, index) => (
            <Price_Box
              key={index}
              label={el.Name}
              price={`$${el?.Amount}`}
              subtext={`Per ${el?.Cycle}`}
              duration={el?.Duration}
              content={el?.Description}
              _id={el?._id}
              item={el}
              style={{
                width: "300px",
                marginBottom: "20px",
              }}
            />
          ))}
          <Subscription_Box
              label={"Continue Free"}
              subtext={<span>&nbsp;</span>}
              content_arr={[
                <span>100 Minutes voice call</span>,
                <span>100 Minutes of video call</span>,
                <span>&nbsp;</span>,
                <span>&nbsp;</span>,
              ]}
              btn_text={"Continue"}
              path={"/dashboard"}
            />
        </Flex_box>
      </Flex_box>
    </Layout>
  );
};

export default Sub_Pages;