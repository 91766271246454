import React from "react";
import footer_bg from "../../../assets/Group2255.svg";
import bg2 from "../../../assets/Path11389.png";
import logo from "../../../assets/Group 40944.png";
import Flex_box from "../../../utilities/flex_box";
import Nav_Link from "./Nav_Link";
import facebook_icon from "../../../assets/Group 48347.svg";
import linkedin_icon from "../../../assets/Group 48348.svg";
import insta_icon from "../../../assets/Group 48349.svg";
import { theme_color } from "../../../utilities/colors";
import zIndex from "@mui/material/styles/zIndex";
import { useMediaQuery } from "@mui/material";
// import logo from "../../../assets/Group 40944.png";

const Footer = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <>

      {/* <Flex_box
        style={{
          justifyContent: "center",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "",
          gap: 40,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <img
            src={logo}
            style={{
              height: "40px",
            }}
          />
          <p
            style={{
              fontSize: "13px",
              width: "280px",
            }}
          >
            Yaarnbox enables businesses to verify, authenticate, and engage
            customers globally.
          </p>
          <Flex_box
            style={{
              boxSizing: "border-box",
              gap: 20,
              zIndex: 101,
              justifyContent: "start",
            }}
          >
            <img
              src={facebook_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={linkedin_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={insta_icon}
              style={{
                height: "30px",
              }}
            />
          </Flex_box>
        </div>
        <div
          style={{
            fontSize: "13px",
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <b
            style={{
              fontSize: "15px",
            }}
          >
            Company
          </b>
          <p>About Yaarnbox</p>
          <p>Careers</p>
          <p>Terms of service</p>
          <p>Privacy Policies</p>
          <p>Compliance</p>
          <p>Annual Report (2023)</p>
        </div>
        <Flex_box
          style={{
            width: "",
            flexWrap: "wrap",
            gap: 10,
            justifyContent: "start",
            alignItems: "",
          }}
        >
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/us.122a7364.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>SAN JOSE, CA</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/nig.b73fdda9.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>LAGOS, NG</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/Cote.d6c74cee.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>ABIDJAN, CI</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
        </Flex_box>
      </Flex_box> */}

      <footer>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <a href="#" class="footerlogo">
                <img src="assets/images/logo.png" width="200px" />
              </a>
              <p class="text-white">Yaarnbox enables businesses to verify, authenticate, and engage customers globally.</p>
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank"><i class="fa-brands fa-facebook"></i> </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank"><i class="fa-brands fa-instagram"></i> </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank"><i class="fa-brands fa-linkedin"></i>  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-9">
              <div class="">
                <div class="row justify-content-center ">
                  <div class="col-md-3">
                    <h4 class="footer-title">COMPANY</h4>
                    <ul class="f-list">
                      <li><a href="#">About Yaarnbox </a> </li>
                      <li><a href="#">Careers </a> </li>
                      <li><a href="#">Terms of service </a> </li>
                      <li><a href="#">Privacy Policies </a> </li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <h4 class="footer-title">SAN JOSE, CA </h4>
                    <ul class="f-list">
                      <li><a href="#">2880 Zanker Road Suite 203 San Jose CA 95134 </a> </li>
                      <li><a href="#">1-669-241-1742 </a> </li>
                      <li><a href="#">xyz@mail.com </a> </li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <h4 class="footer-title">LAGOS, NG </h4>
                    <ul class="f-list">
                      <li><a href="#">2880 Zanker Road Suite 203 San Jose CA 95134 </a> </li>
                      <li><a href="#">1-669-241-1742 </a> </li>
                      <li><a href="#">xyz@mail.com </a> </li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <h4 class="footer-title">ABIDJAN, CI</h4>
                    <ul class="f-list">
                      <li><a href="#">2880 Zanker Road Suite 203 San Jose CA 95134 </a> </li>
                      <li><a href="#">1-669-241-1742 </a> </li>
                      <li><a href="#">xyz@mail.com </a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
