import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Welcome_Comp from "./Welcome_Comp";
import Key_Features from "./Key_Features";
import Why_SmartQ from "./Why_SmartQ";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import The_Value from "./The_Value";
import SmartQDifference from "./SmartQDifference";
import Team_members from "./Team_members";
import FadeInSection from "../../../utilities/FadeInSection";

const Id_Cont = ({ id, children }) => {
  return (
    <FadeInSection>
      <div style={{ width: "100%" }} id={id}>
        {children}
      </div>
    </FadeInSection>
  );
};

const About_Screen = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>

      {/* <Id_Cont id={"nav_comp"}>
        <div
          style={{
            width: "100%",
            height: "90px",
          }}
        ></div>
      </Id_Cont>
      <Id_Cont id={"welcome_comp"}>
        <Welcome_Comp open_modal={open_modal} />
      </Id_Cont>
      <Id_Cont id={"key_features"}>
        <Key_Features />
      </Id_Cont>
      <Id_Cont id={"team_members"}>
        <Team_members />
      </Id_Cont>
      <Id_Cont id={"the_value"}>
        <The_Value />
      </Id_Cont>
      <Id_Cont id={"why_smartq"}>
        <Why_SmartQ />
      </Id_Cont>
      <Id_Cont id={"smart_q_diff"}>
        <SmartQDifference />
      </Id_Cont> */}


      <section class="innerbanner-sec">
		<div class="container">    
			<div class="page-title">
				<h1 class="page-title-inner">About Us</h1> 
				<nav aria-label="breadcrumb" class="breadcrumb-row "><ul class="breadcrumb"><li class="breadcrumb-item"><a href="/">Home</a></li><li class="breadcrumb-item active" aria-current="page">About Us</li></ul></nav>
			</div>  
		</div>
		<img src="assets/images/shape1.png" class="shape shape-1" alt="banner"/>
		<img src="assets/images/shape2.png" class="shape shape-2" alt="banner"/>
	</section>

	<section class="research-sec2">
		<div class="container"> 
			<div class="banner"> 
				<div class="row align-items-center">
					<div class="col-md-7">
						<h4 class="mb-3">The Story Behind Yaarnbox</h4>
						<p>Yaarnbox was born from a vision to bridge the gap between traditional market research methods and the fast-paced digital world. We believe that research should be accessible, and efficient, and deliver actionable insights that drive real-world results.</p> 
						<p>Our team of passionate researchers and developers saw a need for a platform that empowered both researchers and respondents. With Yaarnbox, researchers can access powerful tools to conduct high-quality studies, while respondents gain a convenient and rewarding platform to contribute their valuable perspectives.</p> 
					 
						<div class=" mt-5">
							<a href="#" class="default-btn">
							  <span>Contact Us</span>
							</a> 
						</div>
					</div> 
					<div class="col-md-5">
						<img src="assets/images/about.png" class="amimy" width="100%"/>
					</div> 
				</div>
			</div>
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>
	<section class="position-relative py-3">
		<div class="container"> 
			<div class="banner"> 
				<div class="row align-items-center">
					<div class="col-md-4 ">
						<img src="assets/images/bgmission.png" class="amimy" width="75%"/>
					</div> 
					<div class="col-md-8">
						<h3 class="mb-3" title="Mission">
						{/* <!-- <img src="assets/images/goal.png" alt="Mission" width="50px">  --> */}
						Mission</h3>
						<p>We will empower researchers with innovative tools and connect them with a diverse pool of engaged respondents, ultimately shaping the future of market research through data-driven insights.</p> 
						<p>We will empower researchers with innovative tools and connect them with a diverse pool of engaged respondents, ultimately shaping the future of market research through data-driven insights.</p> 
					  
					</div> 
				</div>
			</div>
		</div>   
	</section>
	
	<section class="position-relative py-5">
		<div class="container"> 
			<div class="banner">  
				<div class="row justify-content-center align-items-center mb-5">
					<div class="col-md-8 text-center">
						<h2 class="mb-3">Our Team Members</h2> 
						<p>The Termii Board of Directors brings years of experience in telecommunications, financial technology services and software.</p> 
					</div>   
				</div>
				<div class="row justify-content-center align-items-center">
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>    
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>    
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>    
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>   
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>   
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>   
					<div class="col-lg-3 col-md-4 col-sm-6 text-center">
						<div  class="teambox">
							<div  class="teamimg">
								<img src="assets/images/team.png"/>
							</div>   
						</div>   
						<div  class="teaminfo">
							<h5 class="mb-1">Our Team Members</h5> 
							<p>The Termii Directors</p> 
						</div>   
					</div>    
				</div>
			</div>
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>
	
	<section class="position-relative py-5">
		<div class="container"> 
			<div class="banner"> 
				<div class="row justify-content-center align-items-center mb-3">
					<div class="col-md-12">
						<h2 class="mb-3">The Value</h2> 
						<p>Yaarnbox offers a comprehensive suite of features to cater to all your research needs</p> 
					</div>   
				</div>
				<div class="row align-items-center">
					<div class="col-md-12">
						<div class="dftcrd">
							<strong>Survey Creation: </strong>Craft engaging surveys with various question formats, branching logic, quota management, and multimedia content integration.
						</div> 
						<div class="dftcrd">
							<strong>Group Discussions: </strong>Facilitate interactive group discussions with real-time text chat, audio conferencing, and video capabilities.
						</div> 
						<div class="dftcrd">
							<strong>Respondent Matching:  </strong>Target the right audience for your studies using advanced demographic filters and preferences.
						</div> 
						<div class="dftcrd">
							<strong>Data Analytics and Reporting: </strong>Gain in-depth understanding of your data through intuitive dashboards, insightful reports, and powerful visualization tools.
						</div> 
						<div class="dftcrd">
							<strong>Data Security and Compliance:</strong>We prioritize the security of your data with industry-standard encryption protocols and rigorous KYC verification processes.
						</div>  
					</div>  
				</div>
			</div>
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>

	<section class="position-relative py-5">
		<div class="container"> 
			<div class="banner"> 
				<div class="row align-items-center">
					<div class="col-md-7">
						<h2>Benefits for Researchers</h2>
						<h5 class="my-4">Streamline your research process and collect high-quality data from a qualified pool of respondents.</h5>
						<p><strong>Improved Data Quality:</strong> Utilize advanced targeting options to ensure you reach the right audience for your studies.</p>  
						<p><strong>Deeper Insights:</strong>  Leverage comprehensive data analysis tools to uncover hidden patterns and gain actionable insights from your data.</p>  
						<p><strong>Cost-Effectiveness:</strong>  Choose from flexible pricing plans to suit your research budget and maximize your return on investment.</p>  
						<p><strong>Collaboration Tools:</strong>  Share your research findings and collaborate seamlessly with colleagues within the platform.</p>  
					  
					</div> 
					<div class="col-md-5">
						<img src="assets/images/research1.png" class="amimy" width="100%"/>
					</div> 
				</div>
			</div>
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>
	
	
	<section class="position-relative py-5">
		<div class="container"> 
			<div class="banner"> 
				<div class="row align-items-center">
					<div class="col-md-5">
						<img src="assets/images/benefits.svg" class="amimy" width="100%"/>
					</div> 
					<div class="col-md-7">
						<h2>Benefits for Respondents</h2>
						<h5 class="my-4">Participate in studies from any device, at your own pace, and contribute your valuable opinions on the go.</h5>
						<p><strong>Flexible Participation:</strong> Choose from a wide range of research topics that interest you and make your voice heard.</p>  
						<p><strong>Rewarding Experience:</strong>  Earn incentives for participating in studies and contribute to shaping the future of products and services..</p>  
						<p><strong> Safe and Secure Platform:</strong>  Enjoy a secure environment with robust data privacy measures in place to protect your information.</p>   
					  
					</div> 
				</div>
			</div>
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>
	
	<section class="position-relative py-5">
		<div class="container">  
			<div class="row justify-content-center align-items-center mb-5">
				<div class="col-md-8 text-center">
					<h2 class="mb-3">Ready to Experience the Yaarnbox Difference?</h2> 
					<p>Join the thousands of researchers and respondents already using Yaarnbox to unlock the power of market research <strong>Call to Action section</strong></p> 
				</div>   
			</div>
			<div class="row align-items-center justify-content-center ">
				<div class="col-md-5">
					 <div class="call_action_box ">
						<h5>Researchers:</h5>
						<p>Sign up for a free trial and experience the comprehensive research toolkit Yaarnbox offers.</p>
						<a href="login.html" class="default-btn clip-btn">
						  <span>Sign Up for Free Trial</span>
						</a>
					 </div>
				</div>  
				<div class="col-md-5">
					 <div class="call_action_box ">
						<h5>Respondents:</h5>
						<p>Create your free account and start participating in exciting studies and earning rewards!</p>
						<a href="login.html" class="default-btn clip-btn">
						  <span>Sign Up for Free</span>
						</a>
					 </div>
				</div>  
			</div> 
		</div> 
		<img src="assets/images/shape1.png" class="shape shape-3" alt="banner"/>
		<img src="assets/images/shape-4.png" class="shape shape-4" alt="banner"/>
		<img src="assets/images/shape3.svg" class="shape shape-5" alt="banner"/>
	</section>


      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default About_Screen;
