import React, { useState } from "react";
import Flex_box from "../../../../../utilities/flex_box";
import blue_circle from "../../../../../assets/Group 38514.svg";
import green_check from "../../../../../assets/check-circle-solid.svg";
import Text_Input from "../../../../../utilities/Text_Input";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import Radio_Box from "../../../../../utilities/Radio_Box";
import { Checkbox, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Option_Box = ({ item, type }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        // gap: 5,
        marginTop: "5px",
        width: "100%",
      }}
    >
      {type == 2 && (
        <Checkbox
          inputProps={{ "aria-label": "controlled" }}
        />
      )}
      {type == 3 && (
        <Radio
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
      )}
      <span
        style={{
          fontSize: "11px",
          marginLeft: type == 4 ? "10px" : "0px",
        }}
      >
        {item.optiontitle}
      </span>
    </Flex_box>
  );
};

const MCQ_Comp = ({ item, index }) => {
  return (
    <>
      <span
        style={{
          fontSize: "12px",
          marginLeft: "7px",
          // marginTop: "15px",
          marginBottom: "5px",
        }}
      >
        Q{index + 1} {item?.questiontitle}
      </span>
      {item?.Option?.length > 0 &&
        item?.Option.map((li, ind) => {
          return (
            <>
              <Option_Box item={li} type={item.questiontype} key={ind} />
              {li.sub_options?.length > 0 &&
                li?.sub_options?.map((elem, ind_el) => {
                  return <Option_Box item={elem} type={2} key={ind_el} />;
                })}
            </>
          );
        })}
    </>
  );
};

const MCQ_Box = ({
  item,
  index,
  q_data,
  setq_data,
  setQuestions,
  image,
  handelEditibleQuestion,
}) => {
  const navigate = useNavigate();

  return (
    <Flex_box
      className="responsive_width_35"
      style={{
        padding: "15px",
        paddingRight: "0px",
        // paddingTop: "0px",
        borderRadius: "8px",
        flexDirection: "column",
        width: "",
        justifyContent: "start",
        alignItems: "start",
        boxShadow: "0px 0px 10px #0000000D",
      }}
    >
      <MCQ_Comp item={item} index={index} />
      <Flex_box
        style={{
          paddingTop: "20px",
          gap: 20,
          justifyContent: "start",
        }}
      >
        <button
          id="btn_comp"
          style={{
            // marginTop: "40px",
            backgroundColor: theme_bg,
            border: "2px solid " + theme_color,
            color: theme_color,
            fontWeight: 600,
            padding: "8px",
            width: "150px",
          }}
          onClick={() => {
            // navigate("/add-survey-questions");
            handelEditibleQuestion(index);
          }}
        >
          Edit
        </button>
        <button
          id="btn_comp"
          style={{
            backgroundColor: "red",
            fontWeight: 600,
            padding: "10px",
            width: "150px",
            color:"#fff",
            border:"2px solid red"
          }}
          onClick={() => {
            // navigate("/select-study-type");

            const new_obj = {
              ...q_data,
              Questions: q_data.Questions?.filter((el, id) => {
                return id != index;
              }),
            };
            setQuestions(new_obj?.Questions);
            setq_data(new_obj);
            localStorage.setItem("question_data", JSON.stringify(new_obj));
          }}
        >
          Delete
        </button>
      </Flex_box>
    </Flex_box>
  );
};

export default MCQ_Box;
