import React from 'react'
import Text_Input from '../../../utilities/Text_Input';
import Flex_box from '../../../utilities/flex_box';
import Button from '../../../utilities/Button';

const Contact_Comp = () => {
    return (
        <div
            style={{
                padding: "20px",
                maxHeight: "79vh",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "100%"
            }}
        >
            <span
                style={{
                    fontSize: "26px",
                    fontStyle: "italic",
                    fontWeight: "700"
                }}
            >
                Contact
            </span>
            <Flex_box
                className="responsive_width_35"
                style={{
                    width: '',
                    gap: 20,
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                <Text_Input
                    label={'Name'}
                    cont_style={{
                        width: "100%"
                    }}
                // input_class_name={'responsive_width_35'}
                />
                <Text_Input
                    label={'Email'}
                    cont_style={{
                        width: "100%"
                    }}
                // input_class_name={'responsive_width_35'}
                />
                <Text_Input
                    label={'Message'}
                    cont_style={{
                        width: "100%"
                    }}
                    // input_class_name={'responsive_width_35'}
                    input_style={{
                        height: '60px'
                    }}
                    textArea={true}
                />
            </Flex_box>
            <Flex_box
                className="responsive_width_35"
                style={{
                    width: '',
                    gap: 30,
                    marginTop: "20px",
                }}
            >
                <Button
                    id='btn_comp'
                    style={{
                        fontWeight: 600
                    }}
                    onClick={() => {
                        // navigate("/add-survey-questions");
                    }}
                    label={'Send Message'}
                >
                </Button>
                <Button
                    id='btn_comp'
                    style={{
                        fontWeight: 600,
                        color: "#000",
                        backgroundColor: "#FFCC99",
                        border:"2px solid #FFCC99"
                    }}
                    onClick={() => {
                        // navigate("/add-survey-questions");
                    }}
                    label={'Chat with Admin'}
                >
                </Button>
            </Flex_box>
        </div>
    )
}

export default Contact_Comp
