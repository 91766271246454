import Chats from "../screens/Chat_Screens/pages/Chats";
import Dashboard from "../screens/Researcher_screens/Dashboard";
import FAQ from "../screens/Researcher_screens/FAQ";
import KYC from "../screens/Researcher_screens/KYC/index.js";
import My_GDs from "../screens/Researcher_screens/My_GDs";
import Create_GD from "../screens/Researcher_screens/My_Studies/Create_GD";
import Create_Survey from "../screens/Researcher_screens/My_Studies/Create_Survey";
import Add_Questions from "../screens/Researcher_screens/My_Studies/Create_Survey/Add_Questions";
import Preview_Survey from "../screens/Researcher_screens/My_Studies/Create_Survey/Preview_Survey";
import Create_study from "../screens/Researcher_screens/My_Studies/Create_study";
import GD_Chat from "../screens/Researcher_screens/My_Studies/GD_Chat";
import Study_Intro_Page from "../screens/Researcher_screens/My_Studies/Intro_Page";
import Resp_list from "../screens/Researcher_screens/My_Studies/Resp_list.js";
import Select_Study_Type from "../screens/Researcher_screens/My_Studies/Select_Study_Type";
import Send_Invite from "../screens/Researcher_screens/My_Studies/Send_Invite";
import Study_Details from "../screens/Researcher_screens/My_Studies/Study_Details";
import Survey_Analysis from "../screens/Researcher_screens/My_Studies/Survey_Analysis";
import Edit_Profile from "../screens/Researcher_screens/Profile/Edit_Profile";
import My_Profile from "../screens/Researcher_screens/Profile/My_Profile";
import Active_Subscription from "../screens/Researcher_screens/Active_Subscriptions/Index.js";
import Subscriptions from "../screens/Researcher_screens/Subscriptions_Packages/Sub_Pages.js"
// import Multi_user_VC from "../screens/Video_Chat/Multi_user_VC/index.js";
// import VideoCall from "../screens/Video_Chat/VideoCall";
// import V_Call from "../screens/Video_Chat/agora_ng_version/V_Call.tsx";

export const researcher_routes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/create-study",
    element: <Create_study />,
  },
  {
    path: "/select-study-type",
    element: <Select_Study_Type />,
  },
  {
    path: "/create-survey",
    element: <Create_Survey />,
  },
  {
    path: "/add-survey-questions",
    element: <Add_Questions />,
  },
  {
    path: "/preview-survey",
    element: <Preview_Survey />,
  },
  {
    path: "/create-gd",
    element: <Create_GD />,
  },
  {
    path: "/send-invite",
    element: <Send_Invite />,
  },
  {
    path: "/gd-chat",
    element: <GD_Chat />,
  },
  {
    path: "/my-profile",
    element: <My_Profile />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/study-details",
    element: <Study_Details />,
  },
  {
    path: "/survey-analysis",
    element: <Survey_Analysis />,
  },
  {
    path: "/edit-profile",
    element: <Edit_Profile />,
  },
  {
    path: "/study-intro",
    element: <Study_Intro_Page />,
  },
  {
    path: "/chat-comp",
    element: <Chats />,
  },
  {
    path: "/my-gds",
    element: <My_GDs />,
  },
  {
    path: "/resp-list",
    element: <Resp_list />,
  },
  {
    path: "/kyc",
    element: <KYC />,
  },
  {
    path: "/subscription",
    element: <Subscriptions />,
  },
  {
    path: "/active-subscription",
    element: <Active_Subscription />,
  },
  // {
  //     path: '/video-chat-comp',
  //     element: <Multi_user_VC />
  // },
];
