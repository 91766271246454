import React, { useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import File_input from "../../../utilities/File_input";
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from "../../../utilities/Text_Input";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import Radio_Box from "../../../utilities/Radio_Box";
import Auth_header from "../Auth_header";
import { useDispatch, useSelector } from "react-redux";
import {
  login_action,
  set_login_type_action,
  set_token_action,
  set_user_action,
} from "../../../redux_prog/actions/auth_action";
import { login_api } from "../../../apis/apis";
import { alert_action } from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import axios from "axios";
import { handleKeyPress } from "../../../utilities/KeyPressFunc";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";
import { BASE_URL, logUserActivity_api } from "../../../apis/apis";

const Sign_in = () => {
  const path_state = useLocation();
  const study_id = path_state?.state?.study_id;
  const gd_path_data = {
    id: study_id,
    type: 2,
  };

  const navigate = useNavigate();
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const [user_data, setuser_data] = useState({});

  const upd_user_data = (key, val) => {
    setuser_data({
      ...user_data,
      [key]: val,
    });
  };

  const { email, password } = user_data;

  const login = () => {
    if (email && password) {
      setloading(true);
      login_api({
        Email: email,
        Password: password,
      })
        .then(async (res) => {
          const logData = {
            userId: res?.data?.status?._id || null,
            email: email,
            action: "Login Attempt",
            role: login_type,
            status: "", // We'll define this later based on the result
          };
          // console.log({ res });
          if (res.data.status && res.data.status?.role == login_type) {
            dispatch(set_token_action(res.data.accessToken));
            setloading(false);
            dispatch(login_action());
            dispatch(set_user_action(res.data.status));
            dispatch(set_temp_user_action(""));
            logData.status = "success"; // Update status for logging
            logUserActivity_api(logData);
            if (study_id) {
              navigate("/study-details", {
                state: gd_path_data,
              });
            } else {
              navigate("/dashboard");
            }
          } else {
            setloading(false);
            dispatch(
              alert_action({
                title: `Invalid Credentials`,
                type: "error",
                msg: "Please provide correct credentials!",
              })
            );
            logData.status = "failed"; // Update status for logging
            logUserActivity_api(logData); // Log failed login due to invalid credentials
          }
        })
        .catch((err) => {
          setloading(false);
          dispatch(
            alert_action({
              title: `Invalid Credentials`,
              type: "error",
              msg: "Some error occurred!",
            })
          );
          // Log failed login due to an error
        logUserActivity_api({
          userId: null, // No userId available in error cases
          email: email,
          action: "login",
          status: "failed",
        });
          console.log({ err });
        });
    } else {
      dispatch(
        alert_action({
          title: `Invalid Credentials`,
          type: "error",
          msg: "Fill all the fields!",
        })
      );
      // Log failed login due to missing fields
    logUserActivity_api({
      userId: null,
      email: email,
      action: "login",
      status: "failed",
    });
    }
  };

  return (
    <div
      className="auth_box_cont"
      style={{
        overflowY: "auto",
      }}
    >
      <div
        className="auth_box"
        style={{
          padding: "15px",
          paddingBottom: "30px",
          marginTop: "160px",
          marginBottom: "60px",
        }}
      >
        <Auth_header label={"Log In"} />
        <span
          className="responsive_Width"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            padding: "25px 0px",
          }}
        >
          Please enter your information to log into the system.
        </span>

        <Flex_box style={{ gap: 50, marginBottom: "30px" }}>
          <Radio_Box
            label={"RESEARCHER"}
            value="2"
            checked={login_type == 2}
            onChange={(e) => {
              dispatch(set_login_type_action(e));
            }}
          />
          <Radio_Box
            label={"RESPONDENT"}
            value="3"
            checked={login_type == 3}
            onChange={(e) => {
              dispatch(set_login_type_action(e));
            }}
          />
        </Flex_box>

        <Text_Input
          label={"Email"}
          value={email}
          cont_style={{
            width: "90%",
          }}
          onChange={(e) => {
            upd_user_data("email", e.target.value);
          }}
        />
        <div
          style={{
            width: "90%",
          }}
        >
          <Text_Input
            onKeyPress={(e) => {
              handleKeyPress(e, "Enter", login);
            }}
            label={"Password"}
            cont_style={{
              marginTop: "18px",
              width: "100%",
            }}
            value={password}
            onChange={(e) => {
              upd_user_data("password", e.target.value);
            }}
            type={"Password"}
          />
          <div
            style={{
              textAlign: "end",
              whiteSpace: "wrap",
              lineHeight: "10px",
              marginTop: 8,
            }}
          >
            <span
              onClick={() => {
                navigate("/reset-pass");
              }}
              style={{
                cursor: "pointer",
                color: theme_color,
                fontWeight: 600,
                fontSize: "11px",
                fontStyle: "italic",
                marginRight: "5px",
              }}
            >
              Forgot Password?
            </span>
          </div>
        </div>

        <Button
          id="btn_comp"
          style={{
            marginTop: "40px",
          }}
          onClick={() => {
            login();
          }}
          label={"Log In"}
          loading={loading}
        ></Button>

        <span style={{ fontSize: "10px", marginTop: "5px" }}>
          Don't have an account? &nbsp;
          <b
            onClick={() => {
              navigate("/signup", {
                state: { study_id },
              });
            }}
            style={{ color: theme_color, cursor: "pointer" }}
          >
            Sign Up
          </b>
        </span>

        <Flex_box style={{ width: "", gap: 15, marginTop: 25 }}>
          <div
            style={{
              height: "2px",
              width: "70px",
              backgroundColor: theme_color,
            }}
          ></div>
          <span style={{ fontSize: "10px" }}>Or sign in with</span>
          <div
            style={{
              height: "2px",
              width: "70px",
              backgroundColor: theme_color,
            }}
          ></div>
        </Flex_box>

        <div style={{ marginTop: 30 }}>
          <GoogleOAuthProvider clientId="AIzaSyDK4LIBeDs0YjsIsFlJs84ub0FGQqpcvNA">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

export default Sign_in;
