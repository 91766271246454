import React, { useEffect } from "react";
import Create_study_comp from "./Create_study_comp";
import Flex_box from "../../../../utilities/flex_box";
import Sidebar from "../../../Nav_Comp/Sidebar";
import Navbar from "../../../Nav_Comp/Navbar";
import Footer from "../../../Nav_Comp/Footer";
import { useDispatch, useSelector } from "react-redux";
import { check_kyc_status_api, get_kyc_docs_api } from "../../../../apis/apis";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { useNavigate } from "react-router-dom";

const Create_study = () => {
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const get_kyc_status = () => {
    // console.warn(user_data);
    check_kyc_status_api({ UserId: user_data?._id })
      .then((res) => {
        // console.warn(res);
        if (res.data.Res > 0) {
          dispatch(
            alert_action({
              type: "error",
              title: "Unverified KYC",
              msg: "Please update your KYC!",
            })
          );
          navigate(-1);
        } else {
          console.warn(res.data.Res);
         
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(get_kyc_status, []);

  return (
    <Flex_box
      style={{
        justifyContent: "start",
        // paddingBottom: "70px",
      }}
    >
      <Sidebar />
      <div
        className="comp_container"
        style={{
          position: "relative",
        }}
      >
        <Navbar />
        <Create_study_comp />
        <Footer />
      </div>
    </Flex_box>
  );
};

export default Create_study;
