import React, { useEffect, useState } from "react";
import Sidebar from '../../Nav_Comp/Sidebar'
import Flex_box from '../../../utilities/flex_box'
import Navbar from '../../Nav_Comp/Navbar'
import Footer from '../../Nav_Comp/Footer'
import Support_Chat from '../../Support_Chat'
import { theme_bg, theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import { GetActiveSubscriptionById_api } from "../../../apis/apis";
import Subscription_Box from "../Subscriptions/Subscription_Box";
import { useDispatch, useSelector } from "react-redux";


const Index = () => {
    const [subscriptions, setsubscriptions] = useState([]);
    const user_data = useSelector((state) => state?.authReducer?.user_data) || {};
    const { username, email, profilepictuer } = user_data;
    const get_Active_subscriptions = () => {
        GetActiveSubscriptionById_api({
            UserId: user_data?._id,
        })
        .then((res) => {
          setsubscriptions(res.data.Res);
        })
        .catch((err) => {
          console.error(err);
        });
    };
  
    const first_sub = subscriptions?.length > 0 ? subscriptions[0] : "";
  
    useEffect(get_Active_subscriptions, []);
  
    return (
      <Layout>
        <Flex_box
          style={{
            flexDirection: "column",
            justifyContent: "start",
            position: " ",
            top: "120px",
            right: "0px",
            height: "85vh",
            width: "",
            lineHeight: 1.8,
            paddingTop: "20px",
          }}
        >
          <b style={{ fontSize: "24px" }}>Active Subscriptions</b>
          <span style={{ fontSize: "14px" }}>
            Here is Your Currently Active Subscription
          </span>
  
          <Flex_box style={{ gap: 40, marginTop: "25px" }}>
            <Subscription_Box
              label={"Full Access"}
              subtext={"from $" + first_sub?.subscription_details?.Amount + "/" + first_sub?.subscription_details?.Cycle}
              content_arr={[
                `Unlimited voice Call`,
                `Unlimited video Call`,
                `Access to all features`,
                `Lorem ipsum dolor sit amet`,
              ]}
              btn_text={"Currently Active"}
              path={"/sub-price"}
              data={subscriptions}
            />
            {/* <Subscription_Box
              label={"Continue Free"}
              subtext={<span>&nbsp;</span>}
              content_arr={[
                <span>100 Minutes voice call</span>,
                <span>100 Minutes of video call</span>,
                <span>&nbsp;</span>,
                <span>&nbsp;</span>,
              ]}
              btn_text={"Continue"}
              path={"/dashboard"}
            /> */}
          </Flex_box>
        </Flex_box>
      </Layout>
    );
  };
  
  export default Index;